import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import emailjs from 'emailjs-com';

// IMAGES
import ImgBanner from '../../Images/ImgBanner.jpeg'
import BeforeAndAfterImg from '../../Images/BeforeAndAfterImg.png'
import chimneyinspection from '../../Images/chimneyinspection.jpeg'
import chimneysweep from '../../Images/chimneysweep.jpeg'
import chimneyrepair from '../../Images/chimneyrepair.jpeg'
import fireplacebanner from '../../Images/fireplacebanner.jpeg'
import Book from '../../Images/Book.jpeg'
import './HomePage.scss';

const HomePage = () => {
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='HomePage'>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <h1><span>SUPER </span>CHIMNEY SWEEP</h1>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:866-693-5714">866-693-5714</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="Super Chimney Sweep Menu" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="Super Chimney Sweep Menu" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="Super Chimney Sweep Book An Appointment" /></a>
                                    <a href="tel:866-693-5714">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="Super Chimney Sweep Phone Number" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WebSite">
                <div id="mainbanner" className="MainBanner">
                    <div className="MainBannerInfo">
                        <h1>
                            Super Chimney Sweep
                        </h1>
                        <h2>At Super Chimney Sweep, we are proud to provide the local services with the best chimney, fireplace, and vent services. We have been providing these services for over 20 years.</h2>
                        <a href="tel:866-693-5714">866-693-5714</a>
                    </div>
                    <div className="MainBannerImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={ImgBanner} alt="CHIMNEY REPAIR SERVICE" />
                    </div>
                </div>
                <div id='services' className="Services">
                    <div className="Services1">
                        <img src={chimneysweep} alt="CHIMNEY SWEEPING & CLEANING" />
                        <h2>CHIMNEY SWEEPING & CLEANING</h2>
                        <p>
                            A chimney is so much more than a small hole in your roof. It is actually a complex system that carries smoke and fire out of your home. With the right tools, care and maintenance your chimney can provide you years of service. A dirty chimney affects the efficiency of your fireplace or furnace, and could lead to a damaged unit at some point. It’s also connected to your fireplace or furnace, so by not maintaining your chimney you can also damage your fireplace or furnace.
                            <br /><br />
                            That’s why chimney maintenance is important, not just to keep your home heating system running smoothly but also for your safety.
                            <br /><br />
                            Safety and peace of mind in your home are vital to any homeowner. Have our <a href="https://www.csia.org/" target="_blank" rel="noopener noreferrer">Certified</a> Chimney Sweepers keep your home safe, clean, and healthy with our range of services.
                            <br /><br />
                            Did you know that when a chimney is not cleaned and maintained, carbon monoxide can build up in your home?
                            <br /><br />
                            We have <a href="https://www.csia.org/" target="_blank" rel="noopener noreferrer">Certified</a> Chimney Sweepers located around on staff to keep your home safe and clean with a range of services.
                        </p>
                    </div>
                    <div className="Services2">
                        <img src={chimneyinspection} alt="CHIMNEY INSPECTION SERVICE" />
                        <h2>CHIMNEY INSPECTION SERVICE</h2>
                        <p>
                            We know that you want to make sure your chimney is safe, so we offer two Stages of inspection.
                            <br /><br />
                            Stage 1: Our chimney sweep will examine the readily accessible portions of your chimney with a flashlight and will indicate areas that may need work. He will also provide you with a written quote for any repairs or maintenance you may need. A complete assessment of all areas of your chimney and flue that can be viewed without any special tools. Our technician will examine the chimney to make sure it's free of loose material and is structurally sound,  great condition, with no visible signs of damage. During the inspection, the technician should check that there are no obstructions or combustible materials in your chimney.
                            <br /><br />
                            Stage 2: Chimney inspectio It includes the same basic steps as stage 1, but with additional information such as: we will do an inspection and provide a comprehensive report of everything that needs repair.  We will also perform an examination of the attic, crawlspace, and other accessible areas to determine if there are any additional problems.
                            <br /><br />
                            We’re here to help you with any chimney issues. Call us at <a href={`tel:866-693-5714`}>866-693-5714</a> and set up an appointment to get your questions answered or your problems solved.
                        </p>
                    </div>
                    <div className="Services3">
                        <img src={chimneyrepair} alt="CHIMNEY REPAIR SERVICE" />
                        <h2>CHIMNEY REPAIR SERVICE</h2>
                        <p>
                            Super Chimney Sweep is a chimney repair company that has been in business for more than 20 years. We've seen every kind of chimney problem there is, and we're here to help you solve yours.
                            <br /><br />
                            Chimneys can get damaged in a number of ways, from small cracks to big holes. Here are just a few ways it can happen: Due to the exposure to the elements, mortar may breakdown and fall away, exposing the bricks and creating holes. The mortar can also dry out resulting in cracks in the brickwork. You can often tell something is wrong with your chimney by the way it vented. Is your chimney making a strange noise? Are you are having problems with smoke coming back into your home? These may be signs that your chimney needs to be professionally examined by a chimney sweep. Super Chimney Sweep professionals quickly diagnose the problem and fix it.
                            <br /><br />
                            As a qualified mason, our team members are highly skilled in chimney repairs and maintenance. Our professionals will ensure that your chimney is free from any blockages, cracks and damage, so that you can use it safely for many years to come. Our chimney sweep team are some of the most knowledgeable in the business. Our extensive services include wood stove and fireplace repairs, inspection and maintenance, and cleaning services of fireplaces, wood stoves and chimneys. We can clean any type of chimney and perform all necessary repairs to keep you safe and comfortable in your own home. Our men are all top-notch and every one of them is approved by our founder, who has spent years hunting the best talent in the business.
                        </p>
                    </div>
                </div>
                <div className="ServicesCallNow">
                    <div className="ServicesCallNowInfo">

                        <h1>
                            Ready to get started with our quality service?
                            <br />
                            Just click the button below and call us now!
                        </h1>
                        <a href={`tel:866-693-5714`}>CALL NOW !</a>
                    </div>
                    <div className="ServicesCallNowImg">
                        <div className="ServicesCallNowImgDark"></div>
                        <img src={fireplacebanner} alt="CHIMNEY SWEEPING & CLEANING" />
                    </div>
                </div>
                <div className="ServicesMore">

                    <h1>MORE OF OUR SERVICES:</h1>
                    <div className="ServicesMoreBoxMain">
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cap Repair" />
                            <h3>Chimney Cap Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cleaning" />
                            <h3>Chimney Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Construction" />
                            <h3>Chimney Construction</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Crown Repair" />
                            <h3>Chimney Crown Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Installation" />
                            <h3>Chimney Flue Installation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Repair" />
                            <h3>Chimney Flue Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Inspection" />
                            <h3>Chimney Inspection</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Installation" />
                            <h3>Chimney Installation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Insulation" />
                            <h3>Chimney Insulation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Repair" />
                            <h3>Chimney Liner Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Maintenance" />
                            <h3>Chimney Maintenance</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Pointing" />
                            <h3>Chimney Pointing</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Rain Cap Installation" />
                            <h3>Chimney Rain Cap Installation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Repair" />
                            <h3>Chimney Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Restoration" />
                            <h3>Chimney Restoration</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Vent Installation" />
                            <h3>Chimney Vent Installation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flashing Repair" />
                            <h3>Chimney Flashing Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Installation" />
                            <h3>Chimney Liner Installation</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Damper Repair" />
                            <h3>Chimney Damper Repair</h3>
                        </div>
                    </div>
                </div>
                <div className="BeforeAndAfter">
                    <h1>Is it time to clean your chimney?</h1>
                    <img src={BeforeAndAfterImg} alt="Is it time to clean your chimney?" />
                    <h2>There are several factors that increase the risk of chimney fires. These include:</h2>
                    <h3>- Not having your chimney swept regularly can lead to a buildup of soot inside the flue, which reduces air flow and impedes efficiency.</h3>
                    <h3>- Chimney Cleaning services can help increase efficiency and avoid costly repairs, or even a carbon monoxide leak.</h3>
                    <h3>- For a warmer, cleaner burn with less pollution, burn only seasoned wood and chimney care products designed to slow the production of creosote.</h3>
                </div>
                <div className="BookAnAppointmentMain">
                    <div className="BookAnAppointmentMainLeft">
                        <h1>The professionals at Super Chimney Sweep are ready to help you</h1>
                        <p>
                            If you are looking for a reliable and friendly party, then look no further than Super Chimney Sweep. We will provide you with your needs in a timely manner. If you would like to learn more about the services we offer, please call today!
                            <br /> <br />
                            Keep your chimney in good working order with annual inspection and repair. Trust the experts at Quality Chimney to keep your chimney and fireplace ready for use when you are, so it can easily and efficiently heat your home or provide ambiance, year after year.
                        </p>
                    </div>
                    <div className="BookAnAppointmentMainRight">
                        <div className="BookAnAppointmentMainRightInfo">
                            <h1>Book an Appointment Online Now...</h1>
                            <button onClick={() => setBookAnAppointment(true)}>BOOK NOW</button>
                        </div>

                    </div>
                    <div className="BookAnAppointmentMainRightImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={Book} alt="Is it time to clean your chimney?" />
                    </div>
                    {
                        BookAnAppointment ?
                            <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                            :
                            null}
                </div>
                <div id='whyus' className="WhyUs">
                    {/* <img src="https://i.insider.com/61dca5f64ede320018b6e5a6?width=900&format=jpeg&auto=webp" alt="" /> */}
                    <div className="WhyUsTitle">
                        <h1>WHY CHOOSE US?</h1>
                        <p>Chimney cleaning is not something that you can do yourself. It requires extensive knowledge, experience and skill which is why we at Super Chimney Sweep are here for your needs. Our professional and extremely experienced team will make sure that your chimney is cleaned thoroughly and professionally. We offer chimney sweeps services for all types of fireplaces, stoves and vents in the area. If you need anything done to your chimney or fireplace then call us today to schedule an appointment if you need anything done to your chimney.</p>
                    </div>
                    <div className="WhyUsList">
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2780/2780505.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Maintaining your chimney and fireplace is critical in ensuring a safe and comfortable home.</h2>
                                <h3>A clean chimney will help you avoid several problems, including health issues that can be caused by poor air quality and fire hazards, as well as damage to your property. With proper maintenance and cleaning, you can prevent these problems from arising in the first place.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2212/2212838.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>A chimney and fireplace inspection will help protect you, your loved ones and your investment.</h2>
                                <h3>Chimney fire danger starts early each year when winter weather sets in. If you’ve been avoiding a chimney inspection, now is the time to get your chimney checked. Annual inspections are a modest investment that can help prevent fires and keep you and your family safe.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/807/807303.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Chimney Masonry, Repair and Installations</h2>
                                <h3>We offer a complete range of restoration and installation services. Whether you need extensive repair work to your existing fireplace or need to replace it with a new one, we can help you efficiently restore your fireplaces back to a beautiful state of repair.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer' className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`Super Chimney Sweep`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>If you have any questions at all, please feel free to contact us. We are available via phone or email. You can also use a quick contact form below or visit us personally.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt={`Super Chimney Sweep Phone Number 866-693-5714`} />
                            <h2>Phone Number: <a href={`tel:866-693-5714`}>866-693-5714</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="Super Chimney Sweep Email | info@superchimneysweep.coml" />
                            <h2>Email: <a href="mailto:info@superchimneysweep.com">info@superchimneysweep.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="Super Chimney Sweep Website | www.SuperChimneySweep.com" />
                            <h2>Website: <a href="https://www.superchimneysweep.com/">www.SuperChimneySweep.com</a></h2>
                        </div>

                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars 
                        </h2>
                        <h3>
                            © Copyright 2022 Super Chimney Sweep. All Right Reserved. <span onClick={() => navigate('/sitemap')}>Sitemap</span>
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        {/* <h1>CALL NOW !</h1> */}
                        <a href="tel:866-693-5714">CALL NOW !</a>
                    </div>
                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default HomePage;

import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// Casino
import HomePage from '../../src/Components/HomePage/HomePage'
import Area from '../../src/Components/Area/Area'
import SiteMap from '../../src/Components/SiteMap/SiteMap'
import ThankPage from '../../src/Components/ThankPage/ThankPage'

export default function Router() {
    return (
        <Routes>
            <Route path='*' element={<HomePage replace to="/404" />} />
            <Route path='/:state' element={<Area />} />
            <Route path='/:state/:city' element={<Area />} />
            <Route path='/sitemap' element={<SiteMap />} />
            <Route path='/thankpage' element={<ThankPage />} />
        </Routes>
    )
}
